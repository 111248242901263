import React, { useEffect, useState } from "react";
import { Spinner } from "reactstrap";
import { configList, configUpdate } from "../../services/ConfigurationServices";
import FormInput from "./FormInput";
import { toast } from "react-toastify";
toast.configure();

const Configuration = (props) => {
  const [configurationList, setConfigurationList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getConfiguration = () => {
    setIsLoading(true);
    configList()
      .then((res) => {
        if (res.status === 200) {
          //   console.log("Response", res);
          setConfigurationList(res.data);
          setTimeout(() => {
            setIsLoading(false);
          }, 1000);
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  useEffect(() => {
    getConfiguration();
  }, []);

  const updateConfiguration = (oldValue, newValue, rowID) => {
    if (oldValue !== newValue) {
      // console.log("Old Value", oldValue);
      // console.log("New Value", newValue);
      // console.log("Row ID", rowID);
      setIsLoading(true);
      const data = {
        id: rowID,
        value: newValue,
      };
      configUpdate(data)
        .then((response) => {
          if (response.status === 200) {
            // console.log("Configuration update Response", response);
            getConfiguration();
            toast.success("Configuration updated successfully.", {
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
        })
        .catch((error) => {
          console.log("error", error);
          setIsLoading(false);
        });
    }
  };
  return (
    <div className="container-fluid">
      <div className="row justify-content-between align-items-center">
        <div className="col-md-12">
          <h3 className="module_title">Configuration</h3>
          <p className="module_description">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </p>
        </div>
      </div>
      {isLoading ? (
        <div className="custom-loader linesLoader">
          <Spinner animation="border" />
        </div>
      ) : (
        <div>
          {configurationList.map((group, index) => {
            // console.log("group", group);
            return (
              <>
                <div className="row group_wrapper">
                  <div className="col-md-12">
                    <div className="group_name">
                      <h2>{group.group_display_name}</h2>
                    </div>
                    <div className="group_repeat">
                      <div className="group_repeat_row_parent">
                        {group.service_group.map((group_key, index1) => {
                          // console.log("Group Key", group_key);
                          return (
                            <div className="row group_repeat_row justify-content-space-between align-items-center">
                              <div className="col-md-8">
                                <div className="key_name">
                                  <h3>{group_key.key}</h3>
                                </div>
                                <div className="key_description">
                                  {group_key.description !== null ? (
                                    <p>{group_key.description}</p>
                                  ) : (
                                    <p>{"No Description Available"}</p>
                                  )}
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="key_form_element">
                                  {/* <input
                                    className="form-control bg_change_color"
                                    type="text"
                                    name={`key_input_element_${index1}`}
                                    defaultValue={group_key.value}
                                    onBlur={(e) =>
                                      updateConfiguration(
                                        e,
                                        group_key.value,
                                        group_key.id
                                      )
                                    }
                                  /> */}
                                  <FormInput
                                    index={index1}
                                    defaultValue={group_key.value}
                                    inputId={group_key.id}
                                    updateConfiguration={updateConfiguration}
                                  />
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Configuration;
