import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";

import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
export default class LoginForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  showPassword = () => {
    this.setState({ hidden: !this.state.hidden });
  };

  render() {
    const { isloading } = this.props;
    return (
      <>
        <div className="clearfix login_left_col">
          <h1>Login to your account</h1>
          <p>Welcome back! Please login to your account.</p>
          {
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              onSubmit={(values) => {
                console.log("values", values);
                // return;
                this.props.onSubmit(values);
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email("Please enter valid email address")
                  .required("Please fill required field"),
                password: Yup.string().required("Please fill required field"),
              })}
            >
              {(props) => {
                const {
                  errors,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  touched,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    {this.props.error !== "" ? (
                      <p className="alert alert-danger">{this.props.error}</p>
                    ) : (
                      ""
                    )}
                    <div className={`form-group`}>
                      <label>Email</label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="email"
                          onBlur={handleBlur}
                          className={
                            errors.email && touched.email
                              ? "form-control error"
                              : "form-control"
                          }
                          placeholder=""
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <FontAwesomeIcon icon={faUser} />
                          </div>
                        </div>

                        {errors.email && touched.email && (
                          <div className="input-feedback error_field">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`form-group`}>
                      <label>Password</label>
                      <div className="input-group">
                        <input
                          type={this.state.hidden ? "password" : "text"}
                          name="password"
                          onBlur={handleBlur}
                          className={
                            errors.password && touched.password
                              ? "form-control error"
                              : "form-control"
                          }
                          placeholder=""
                          onChange={handleChange}
                          autoComplete="on"
                        />
                        <div className="input-group-append">
                          <div
                            className="input-group-text pointer_cursor"
                            onClick={this.showPassword}
                          >
                            {this.state.hidden ? (
                              <FontAwesomeIcon icon={faEyeSlash} />
                            ) : (
                              <FontAwesomeIcon icon={faEye} />
                            )}
                          </div>
                        </div>
                      </div>

                      {errors.password && touched.password && (
                        <div className="input-feedback error_field">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary-fill d-block text-center mt-5 w-100"
                        disabled={this.state.isloading}
                      >
                        {!this.props.isloading ? "Login" : "Please Wait"}
                      </button>
                      <a
                        className={"forgot-link"}
                        onClick={this.props.toggleForms}
                      >
                        Forgot password?
                      </a>
                    </div>
                  </form>
                );
              }}
            </Formik>
          }
        </div>
      </>
    );
  }
}
