import React, { memo } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
//import  NotFound  from '../components/common/NotFound';
import ResetPassword from "../auth/ResetPassword";
/*
 * This is the route utility component used for generating
 * routes and child routes it only requires routes array and basePath
 */
function MapAllowedRoutes({ routes, basePath, isAddNotFound }) {
  const match = useRouteMatch(basePath);
  return (
    <Switch>
      {routes
        .filter((route) => route.hidden === undefined || route.hidden !== true)
        .map((route) => {
          // console.log("route", route);
          const {
            path,
            component: Component,
            children,
            title,
            permission,
            ...rest
          } = route;
          return (
            <Route {...rest} key={path} path={`${match.path}${path}`}>
              <Component children={children} />
            </Route>
          );
        })}
    </Switch>
  );
}

export default memo(MapAllowedRoutes);
