import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
// import { Formik, Field } from "formik";
// import * as Yup from "yup";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// toast.configure();
export default class BulkAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_disabled_value: "",
    };
  }

  handleChange = (e) => {
    console.log("e.target.value", e.target.value);
    this.setState({
      is_disabled_value: e.target.value,
    });
  };

  render() {
    const { isloading } = this.props;
    return (
      <>
        <form
          onSubmit={() => this.props.onSubmit(this.state.is_disabled_value)}
        >
          <Modal isOpen={this.props.showModalBulkAction} size="md">
            <ModalHeader>
              <h4>Action</h4>
              <div className="modal_popup_close_icon">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => this.props.handleModalHide()}
                />
              </div>
            </ModalHeader>
            <ModalBody>
              {this.props.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.props.errorMessage}</p>
              ) : (
                ""
              )}
              <div className="form-group form-gp">
                <Row>
                  <Col md="12">
                    <label>{this.props.label}</label>
                  </Col>
                  <Col md="12">
                    <ul className="d-flex flex-wrap radio-inline-parent">
                      <li>
                        <label className="radio-inline">
                          <input
                            type="radio"
                            name="is_disabled"
                            value="TRUE"
                            onChange={this.handleChange}
                          />
                          {this.props.radio1}
                        </label>
                      </li>
                      <li>
                        <label className="radio-inline">
                          <input
                            type="radio"
                            name="is_disabled"
                            value="FALSE"
                            onChange={this.handleChange}
                          />
                          {this.props.radio2}
                        </label>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                type="button"
                onClick={() => this.props.handleModalHide()}
              >
                Close
              </Button>
              <Button
                className="btn btn-primary-theme"
                type="submit"
                color="success"
                disabled={isloading}
                onClick={() =>
                  this.props.onSubmit(this.state.is_disabled_value)
                }
              >
                Submit
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      </>
    );
  }
}
