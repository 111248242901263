import React, { Fragment } from "react";
import { Redirect, useRouteMatch } from "react-router-dom";
import { getAllowedRoutes, isLoggedIn } from "../utils";
import PrivateRoutesConfig from "../config/PrivateRoutesConfig";
import MapAllowedRoutes from "./MapAllowedRoutes";
import Sidebar from "../components/common/Sidebar";

function PrivateRoutes(props) {
  const match = useRouteMatch("/app");
  let allowedRoutes = [];

  if (isLoggedIn()) {
    allowedRoutes = getAllowedRoutes(PrivateRoutesConfig);
    // console.log("allowedRoutes", allowedRoutes);
  } else {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Sidebar
        routes={allowedRoutes}
        path={match.path}
        className="bg-white"
        handleClass={props.handleClass}
      />
      <MapAllowedRoutes routes={allowedRoutes} basePath="/app" isAddNotFound />
    </Fragment>
  );
}

export default PrivateRoutes;
