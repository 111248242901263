import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
} from "reactstrap";
// import { Formik, Field } from "formik";
// import * as Yup from "yup";
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
import CSVReader from "react-csv-reader";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// toast.configure();
export default class UploadLine extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCsv: [],
      fileinfo: {},
      showInfo: false,
      submitButton: true,
      error: "",
    };
  }

  validateNumberField = (keyData = "") => {
    if (!keyData || keyData.toString().length != 10 || isNaN(+keyData)) {
      return true;
    }
    return false;
  };

  handleForce = (data, fileInfo) => {
    console.log(data, fileInfo);

    let isValid = true;
    const finalData = [];
    for (let i = 0; i < data.length; i++) {
      const currentRecord = data[i];
      const {
        phone_number,
        xref_number,
        telnyx_forwarding_number,
      } = currentRecord;
      let newData = { ...currentRecord };
      if (Object.keys(currentRecord).length < 4) {
        console.log("err 1");
        // handle blank and errorr
        isValid = false;
        break;
      }
      if (!currentRecord.carrier) {
        console.log("err 2");
        newData = {
          ...newData,
          carrierError: true,
          // carrier: ""
        };
        isValid = false;
      }
      if (this.validateNumberField(phone_number)) {
        newData = {
          ...newData,
          phone_number_Error: true,
          // phone_number: ""
        };
        isValid = false;
      }
      if (this.validateNumberField(xref_number)) {
        newData = {
          ...newData,
          xref_number_Error: true,
          // xref_number: ""
        };
        isValid = false;
      }
      if (this.validateNumberField(telnyx_forwarding_number)) {
        newData = {
          ...newData,
          telnyx_forwarding_number_Error: true,
          //   telnyx_forwarding_number: "",
        };
        isValid = false;
      }
      finalData.push(newData);
    }

    this.setState({
      dataCsv: finalData,
      fileinfo: fileInfo,
      showInfo: true,
      submitButton: false,
      isValid,
    });
  };

  render() {
    const papaparseOptions = {
      header: true,
      dynamicTyping: true,
      skipEmptyLines: true,
      transformHeader: (header) => header.toLowerCase().replace(/\W/g, "_"),
    };
    const { showInfo, fileinfo, dataCsv, submitButton, isValid } = this.state;
    const { errorCustomMessage } = this.props;
    console.log("errorCustomMessage", errorCustomMessage);
    return (
      <>
        <form onSubmit={() => this.props.onSubmit(dataCsv)}>
          <Modal
            isOpen={this.props.showModalUploadLine}
            size="md"
            className={"custom_modal " + (showInfo ? "big" : "small")}
          >
            <ModalHeader>
              <h4>Upload Lines</h4>
              <div className="modal_popup_close_icon">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => this.props.handleModalHide()}
                />
              </div>
            </ModalHeader>
            <ModalBody>
              {this.props.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.props.errorMessage}</p>
              ) : (
                ""
              )}
              {errorCustomMessage.length > 0 ? (
                <>
                  <p className="alert alert-danger">
                    Could not upload lines present on following
                    rows&nbsp;(&nbsp;
                    {errorCustomMessage.map((rowIndex, index) => {
                      return (
                        <span key={`errorCustomMessage_${index}`}>
                          {(index ? ", " : "") + rowIndex}
                        </span>
                      );
                    })}
                    &nbsp;)&nbsp;in CSV
                  </p>
                </>
              ) : null}
              {!showInfo ? (
                <div className="form-group form-gp">
                  <Row>
                    <Col md="12">{/* <label>Upload CSV File</label> */}</Col>
                    <Col md="12">
                      {/* <input name="file" type="file" title="Select a file" /> */}
                      <div className="file_upload_input">
                        <CSVReader
                          cssClass="react-csv-input"
                          onFileLoaded={this.handleForce}
                          parserOptions={papaparseOptions}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              ) : null}

              {showInfo ? (
                <div className="csv_file_data">
                  <div className="file_info">
                    <label>File Name:: </label>
                    <label>&nbsp;{fileinfo.name}</label>
                  </div>
                  <div className="file_data">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Carrier</th>
                          <th>Phone Number</th>
                          <th>Telnyx Forwarding Number</th>
                          <th>XREF Number</th>
                        </tr>
                      </thead>
                      <tbody>
                        {dataCsv.map((data) => {
                          console.log("data", data);
                          return (
                            <tr>
                              <td className={data.carrierError ? "error" : ""}>
                                {data.carrier}
                              </td>
                              <td
                                className={
                                  data.phone_number_Error ? "error" : ""
                                }
                              >
                                {data.phone_number}
                              </td>
                              <td
                                className={
                                  data.telnyx_forwarding_number_Error
                                    ? "error"
                                    : ""
                                }
                              >
                                {data.telnyx_forwarding_number}
                              </td>
                              <td
                                className={
                                  data.xref_number_Error ? "error" : ""
                                }
                              >
                                {data.xref_number}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              ) : null}
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                type="button"
                onClick={() => this.props.handleModalHide()}
              >
                Close
              </Button>
              <Button
                className="btn btn-primary-theme"
                type="submit"
                color="success"
                disabled={!isValid}
                onClick={() => this.props.onSubmit(dataCsv)}
              >
                Upload
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      </>
    );
  }
}
