import Roles from "./Roles";
import { faUser, faHeadset, faCogs } from "@fortawesome/free-solid-svg-icons";

import Dashboard from "../admin/dashboard/Dashboard";
import ManageLines from "../admin/manage_lines/ManageLines";
import Configuration from "../admin/configuration/Configuration";
import ResetPassword from "../auth/ResetPassword";

// TODO:
/*
 * 1. Make title optional
 * 2. Make title multi type support ie: (string, node, react element)
 * */
const PrivateRoutesConfig = [
  {
    component: Dashboard,
    path: "/app/dashboard",
    title: "Dashboard",
    icon: faUser,
    exact: true,
    permission: [Roles.ADMIN],
  },
  {
    component: ManageLines,
    path: "/app/manage-lines",
    title: "Manage Lines",
    icon: faHeadset,
    exact: true,
    permission: [Roles.ADMIN, Roles.LINEMANAGER],
  },
  {
    component: Configuration,
    path: "/app/configuration",
    title: "Configuration",
    icon: faCogs,
    exact: true,
    permission: [Roles.ADMIN],
  },
];

export default PrivateRoutesConfig;
