import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
} from "reactstrap";
import { Formik, FieldArray, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

toast.configure();
export default class UpdateProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };

  render() {
    const { isloading, lineEnabledCount, dataLoading } = this.props;
    const getFilterValue = JSON.parse(localStorage.getItem("filterValue"));
    console.log("getFilterValue", getFilterValue);
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            console.log(values);
            // return;
            this.props.onSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.showModalUpdateProvider} size="md">
                <ModalHeader>
                  <h4>Update Provider</h4>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {dataLoading ? (
                    <div className="custom-loader linesLoader">
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="form-group form-gp mb-0">
                        <Row>
                          <Col md="5">
                            <label>Carrier Name</label>
                          </Col>
                          <Col md="7">{getFilterValue.carrier}</Col>
                        </Row>
                      </div>
                      <div className="form-group form-gp">
                        <Row>
                          <Col md="5">
                            <label>Total Number Of Lines</label>
                          </Col>
                          <Col md="7">{values.TotalLineNumber}</Col>
                        </Row>
                      </div>
                      <div className="form-group form-gp">
                        <Row>
                          <Col md="12">
                            <div className="react-bootstrap-table react-bootstrap-table-update-provider">
                              <table className="table table-striped table-hover table-bordered table-condensed">
                                <thead>
                                  <tr>
                                    <th>Provider</th>
                                    <th>Lines Allocated (Ratio)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <FieldArray
                                    name="providers"
                                    render={({ insert, remove, push }) => (
                                      <>
                                        {values.providers.length > 0 &&
                                          values.providers.map(
                                            (provider, index) => (
                                              <tr className="" key={index}>
                                                <td>
                                                  <div className="clearfix">
                                                    <lable>
                                                      {provider.provider}
                                                    </lable>
                                                  </div>
                                                </td>
                                                <td>
                                                  <div className="clearfix">
                                                    <Field
                                                      name={`providers.${index}.number_of_lines`}
                                                      type="text"
                                                      values={`providers.${index}.number_of_lines`}
                                                      className="form-control update_provider_count"
                                                    />
                                                  </div>
                                                </td>
                                              </tr>
                                            )
                                          )}
                                      </>
                                    )}
                                  />
                                </tbody>
                              </table>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn btn-primary-theme"
                    type="submit"
                    color="success"
                    disabled={isloading}
                    onClick={handleSubmit}
                  >
                    Update
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
