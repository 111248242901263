import { Api } from "../Api";

export const lineList = () => {
  return Api.get("line/list").then((res) => res.data);
};

export const getCarrier = () => {
  return Api.get("carrier/list").then((res) => res.data);
};

export const filterList = (data) => {
  return Api.post("line/filter-list", data).then((res) => res.data);
};

export const bulkActionIsDisabled = (data) => {
  return Api.post("line/status_changes/is_disabled", data).then(
    (res) => res.data
  );
};

export const updateXrefNumber = (data) => {
  return Api.post("line/update-xref-number", data).then((res) => res.data);
};

export const updateEnableLine = (data) => {
  return Api.post("line/update-enable-line", data).then((res) => res.data);
};

export const enableLine = (data) => {
  return Api.post("line/enable-line", data).then((res) => res.data);
};

export const bulkUpdateLine = (data) => {
  return Api.post("line/bulk-update-line", data).then((res) => res.data);
};

export const bulkActionSnoopCall = (data) => {
  return Api.post("line/snoop-call-list/update", data).then((res) => res.data);
};

export const lineAdd = (data) => {
  return Api.post("line/add", data).then((res) => res.data);
};

export const lineUpdate = (data) => {
  return Api.post("line/update", data).then((res) => res.data);
};

export const updateTelnyxNumber = (data) => {
  return Api.post("line/update-telnyx-number", data).then((res) => res.data);
};

export const getLineProvider = (data) => {
  return Api.post("line/get-lines-provider", data).then((res) => res.data);
};

export const updateLinesProvider = (data) => {
  return Api.put("line/update-lines-provider", data).then((res) => res.data);
};

export const getclients = () => {
  return Api.get("services/client/list").then((res) => res.data);
};

export const getLineProviderList = () => {
  return Api.get("services/lineProvider-list").then((res) => res.data);
};
