import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
 
export default class ViewTranscript extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    //   const this.props.showModalViewTranscript
    return (
      <>
        <Modal isOpen={this.props.handleModalShow} size="md">
          <ModalHeader>
            <h4>View Transcript</h4>
            <div
              className="modal_popup_close_icon"
              onClick={() => this.props.handleModalHide()}
            >
              <FontAwesomeIcon icon={faTimes} />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="form-group form-gp">
              <Row>
                <Col md="12">
                  <textarea
                    Col="5"
                    rows="10"
                    style={{
                      height: "150px",
                      resize: "none",
                    }}
                    defaultValue={
                      this.props.message != null ? this.props.message : "N/A"
                    }
                    className="form-control"
                    readOnly
                    disabled
                  />
                </Col>
              </Row>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              color="secondary"
              type="button"
              onClick={() => this.props.handleModalHide()}
            >
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
