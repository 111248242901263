import { ApiLogin, Api } from "../Api";

export const login = (user) => {
  return ApiLogin.post("auth/login", user).then((res) => res.data);
};

export const forgotPassword = (data) => {
  return Api.post("services/user/forget-password", data).then(
    (res) => res.data
  );
};

export const resetPassword = (data) => {
  return Api.post("services/user/reset-password", data).then((res) => res.data);
};

export const tokenExpire = (data) => {
  return Api.post("services/user/token-expire", data).then((res) => res.data);
};

export const tokenExpireCheck = (data) => {
  return Api.post("user/logout", data).then((res) => res.data);
};
