import React, { Component } from "react";
import { tokenExpireCheck } from "../../services/AuthServices.jsx";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    document.title = "Reachout Sofmen";
    this.checkUserAuth();
  }

  /**
   * Function call user logout API
   * @author   Anil Sharma
   */

  checkUserAuth = () => {
    const getUserDetail = localStorage.getItem("user");
    const parseObject = JSON.parse(getUserDetail);
    const userId = parseObject.user_id;
    const userToken = parseObject.token;
    const prepareData = {
      user_id: userId,
      token: userToken,
    };
    tokenExpireCheck(prepareData)
      .then((res) => {
        if (res.status === 200) {
          console.log("response check User Auth", res);
        } else {
          console.log("Error else");
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch((error) => {
        console.log("Error catch");
        localStorage.clear();
        window.location.href = "/";
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <h1>Welcome Dashboard</h1>
        <a href="https://lrganalytics.sofmen.com/dashboard/33" target="_blank">
          View Analytics
        </a>
      </div>
    );
  }
}

export default Dashboard;
