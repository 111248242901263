import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUpload,
  faSyncAlt,
  faCheck,
  faInfoCircle,
  faEye,
  faStickyNote,
} from "@fortawesome/free-solid-svg-icons";
import { Spinner, Tooltip, Button, UncontrolledTooltip } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import {
  getCarrier,
  filterList,
  bulkActionIsDisabled,
  bulkActionSnoopCall,
  lineAdd,
  bulkUpdateLine,
  updateXrefNumber,
  enableLine,
  updateEnableLine,
  updateTelnyxNumber,
  getLineProvider,
  updateLinesProvider,
  getclients,
  getLineProviderList,
} from "../../services/ManageLineServices";
import check from "../../assets/images/check.png";
import close from "../../assets/images/close.png";
import check_dis from "../../assets/images/check_dis.png";
import upArrow from "../../assets/images/up-arrow.png";
import curveDownArrow from "../../assets/images/curve-down-arrow.png";
import UploadLine from "./UploadLine";
import UpdateXref from "./UpdateXref";
import ShowConfirmModal from "./ShowConfirmModal";
import BulkAction from "./BulkAction";
import ReactAudioPlayer from "react-audio-player";
import Music from "../../components/common/Music";
import ViewTranscript from "./ViewTranscript";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import UpdateProvider from "./UpdateProvider";
import Select, { components } from "react-select";
toast.configure();
class ManageLines extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.myRef1 = React.createRef();
    this.myRefdeleted = React.createRef();
    this.myRefdisabled = React.createRef();
    this.state = {
      isloading: false,
      buttonDisabled: false,
      listLine: [],
      initialValues: {
        carrier: "ALL",
        is_disabled: "ALL",
        is_last_result_successful: "ALL",
      },
      enableFilter: false,

      showUploadModal: false,
      errorMessage: "",
      errorCustomMessage: [],
      bulkAction: false,
      carrier: [],
      selectedRow: [],

      bulkLineSwitch: false,
      isDisabledBulkModal: false,

      isDisabledBulkModalSnoopCall: false,
      bulkLineSwitchSnoopCall: false,

      showModalAddLine: false,
      addLineinitialValues: {
        carrier: "",
        phone_number: "",
        xref_number: "",
        telnyx_forwarding_number: "",
      },

      showModalEditLine: false,
      editLineinitialValues: {},
      toggleDeleted: false,
      toggleDisabled: false,

      bulkActionSelect: "",
      showModalUpdateXref: false,
      updateXrefinitialValues: {
        xref_number: "",
      },

      lineEnabledCarrier: "",
      lineEnabledCount: "",
      enableLineList: [],
      lineEnabledButton: false,
      lineEnabledShowConfirmModal: false,

      filterValue: {
        carrier: "ALL",
        is_deleted: "FALSE",
        is_disabled: "FALSE",
      },
      showModalViewTranscript: false,
      transcriptMesssage: null,
      showFullTranscript: false,
      showModalUpdateProvider: false,
      updateProviderinitialValues: {
        carrier: "",
        providers: [],
        TotalLineNumber: "",
      },
      dataLoading: false,
      providerValue: {},
      listProvider: [],
    };
  }
  componentDidMount() {
    this.getProviderList();
    this.getCarrierList();

    // console.log("process.env", process.env);
    // console.log(
    //   "localStorage getItem filter_value null",
    //   localStorage.getItem("filter_value")
    // );

    if (localStorage.getItem("filterValue") !== null) {
      // console.log("Condition Multiple True ");
      // console.log("True condition");
      const getFilterValue = localStorage.getItem("filterValue");
      const parseObject = JSON.parse(getFilterValue);
      // console.log("getFilterValue", parseObject.is_deleted);
      this.setState(
        {
          filterValue: parseObject,
          toggleDeleted: parseObject.is_deleted,
          toggleDisabled: parseObject.is_disabled,
        },
        () => {
          // this.getLineList();
        }
      );
    } else {
      // console.log("Condition Multiple False ");
      this.setState(
        {
          filterValue: {
            carrier: "ALL",
            is_deleted: "FALSE",
            is_disabled: "FALSE",
          },
          toggleDeleted: false,
          toggleDisabled: false,
        },
        () => {
          // this.getLineList();
        }
      );
      localStorage.setItem(
        "filterValue",
        JSON.stringify(this.state.filterValue)
      );
    }
  }

  /* This Function  will Call API & give Json data of providers */

  getProviderList = () => {
    getLineProviderList()
      .then((res) => {
        if (res.status === 200) {
          console.log("Response provider list", res);
          let providerList = res.data.map(function (provider) {
            return { value: provider.line_provider_id, label: provider.name };
          });
          this.setState({
            listProvider: providerList,
          });
          if (localStorage.getItem("providerID") !== null) {
            const getProviderIDValue = localStorage.getItem("providerID");
            const parseObject = JSON.parse(getProviderIDValue);
            console.log("parseObject", parseObject);
            this.setState(
              {
                providerValue: parseObject,
              },
              () => this.getEnableLine()
            );
          } else {
            localStorage.setItem(
              "providerID",
              JSON.stringify(this.state.listProvider[0])
            );
            this.setState(
              {
                providerValue: this.state.listProvider[0],
              },
              () => this.getEnableLine()
            );
          }
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /* This Function  handle value for the provider change */

  handleProviderChange = (selectProvider) => {
    console.log("selectProvider", selectProvider);

    localStorage.setItem("providerID", JSON.stringify(selectProvider));
    this.setState(
      {
        providerValue: selectProvider,
      },
      () => {
        this.getEnableLine();
        // this.getLineList();
      }
    );
  };

  /* This Function  will Call API & give Json data of Carrier */

  getCarrierList = () => {
    getCarrier()
      .then((res) => {
        if (res.status === 200) {
          // console.log("Response", res);
          this.setState({
            carrier: res.data,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  /* This function will give linelist of select carrier */
  getLineList = () => {
    this.setState({ isloading: true });
    // const data = this.state.filterValue;
    // console.log("getLineList data", data);
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const data = {
      ...this.state.filterValue,
      line_provider_id: getProviderID.value,
    };
    const prepareData = JSON.stringify(data);
    // return;
    console.log("prepareData", prepareData);
    filterList(prepareData)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Response", res);
          // console.log("res.data", res.data);

          let data = JSON.parse(JSON.stringify(res.data));
          // console.log("getLineList", data);
          const updatedData = this.changeStrideValue(data);
          // console.log("getLineListUpdated", updatedData);
          this.setState({
            listLine: updatedData,
            isloading: false,
          });
        }
      })
      .catch((error) => {
        console.log("error", error);
        this.setState({ isloading: false });
      });
  };

  changeStrideValue = (data) => {
    const UpdatedData = data.map((row) => {
      if (row.is_last_result_successful) {
        row.stride = parseInt(row.stride);
        return row;
      } else {
        row.stride = -parseInt(row.stride);
        console.log("row stride", row.stride);
        return row;
      }
    });
    // console.log("updtaed", UpdatedData);
    return UpdatedData;
  };

  /* This function will open upload line modal */
  showUploadModal = () => {
    this.setState({
      showUploadModal: true,
    });
  };

  /* This function will closed upload line modal */
  handleModalHide = () => {
    this.setState({
      showUploadModal: false,
      errorMessage: "",
      errorCustomMessage: [],
    });
  };

  /**This  function will cheked one row of table
  @param row object
   */
  onSelectCheckbox = (row) => {
    // console.log("on SelectCheckbox", row);
    this.setState({
      selectedRow: this.state.selectedRow.concat(row.line_id),
    });
  };

  /**This  function will cheked all row of table
  @param row object
   */
  onAllSelectCheckbox = (row) => {
    // console.log("on SelectCheckbox", row);
    // this.setState({
    //   selectedRow: row,
    // });
    let lineId = [];
    row.map((value) => {
      // console.log("value.line_id", value.line_id);
      lineId.push(value.line_id);
    });
    // console.log("lineId", lineId);
    this.setState({
      selectedRow: lineId,
    });
  };

  /**This  function will uncheked one row of table
  @param row object
   */
  onUnSelectCheckbox = (row) => {
    // console.log("on UnSelectCheckbox SelectCheckbox", row);
    let selectedRow = [...this.state.selectedRow];
    let newarray = selectedRow.filter(function (element) {
      return element != row.line_id;
    });
    // const updateSelectedRow = this.state.selectedRow.filter(
    //   (item) => item.line_id == row.line_id
    // );
    // console.log("updateSelectedRow", newarray);
    this.setState({
      selectedRow: newarray,
    });
  };

  /**This  function will uncheked all row of table
  @param row object
   */
  onAllUnSelectCheckbox = (row) => {
    this.setState({
      selectedRow: [],
    });
  };

  bulkLineUpdate = (value) => {
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const data = {
      line_id: this.state.selectedRow,
      is_disabled: value,
      line_provider_id: getProviderID.value,
    };
    // console.log("bulk Line Update Data", data);
    // return;
    this.setState({
      buttonDisabled: true,
    });
    bulkActionIsDisabled(data)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Res bulk Action Is Disabled", res);
          this.setState(
            {
              isDisabledBulkModal: false,
              selectedRow: [],
              buttonDisabled: false,
            },
            () => {
              this.getLineList();
            }
          );
          toast.success("Is Disabled updated successfully.", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            buttonDisabled: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            buttonDisabled: false,
          });
        }
      });
  };

  /** This function call SnoopCall Api and update snoop next call status
   @param value booolean
   @param line_id number
   * */

  bulkLineUpdateSnoopCall = (value, line_id) => {
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const data = {
      line_id: [line_id],
      snoop_next_call: value,
      line_provider_id: getProviderID.value,
    };
    // console.log("Single Line Update Snoop Call Data", data);
    // return;
    this.setState({
      buttonDisabled: true,
    });
    bulkActionSnoopCall(data)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Res Signle Action Is Disabled", res);
          // this.setState(
          //   {
          //     isDisabledBulkModalSnoopCall: false,
          //     selectedRow: [],
          //     buttonDisabled: false,
          //   },
          //   () => {
          //     this.getLineList();
          //   }
          // );
          toast.success("Snoop Next Call updated successfully.", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            buttonDisabled: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            buttonDisabled: false,
          });
        }
      });
  };

  bulkLineDisabled = () => {
    // console.log("bulkLineDisabled");
    // console.log("this.myRef", this.myRef.current.checked);
    this.setState({
      bulkLineSwitch: false,
      isDisabledBulkModal: false,
    });
    this.myRef.current.checked = false;
  };

  bulkLineDisabledSnoopCall = () => {
    // console.log("bulkLineDisabledSnoopCall");
    // console.log("this.myRef1", this.myRef1.current.checked);
    this.setState({
      bulkLineSwitchSnoopCall: false,
      isDisabledBulkModalSnoopCall: false,
    });
    this.myRef1.current.checked = false;
  };

  handleModalShowAddLine = () => {
    this.setState({
      showModalAddLine: true,
    });
  };

  /** This function add new row in table
   * @param data object
   */
  uploadLine = (data) => {
    console.log("Upload Data ", data);
    // return;
    this.setState({
      buttonDisabled: true,
    });
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prepareData = {
      line_provider_id: getProviderID.value,
      data: data,
    };
    lineAdd(prepareData)
      .then((res) => {
        if (res.status === 200) {
          // console.log("response lineAdd", res);
          const lineList = [...this.state.listLine];
          const mergeData = lineList.concat(res.data);
          this.setState({
            showUploadModal: false,
            listLine: mergeData,
            errorMessage: "",
            buttonDisabled: false,
          });
          toast.success("Upload Line successfully!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          console.log("Error else", res);
          this.setState({
            errorCustomMessage: res.index,
            buttonDisabled: true,
          });
        }
      })
      .catch((error) => {
        console.log("Error catch");
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            buttonDisabled: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            buttonDisabled: false,
          });
        }
      });
  };

  /* This function will give list  */
  toggleDeleted = () => {
    let filterValue = JSON.parse(localStorage.filterValue);
    let letToggleDeleted = "";
    if (this.state.toggleDeleted !== "TRUE") {
      letToggleDeleted = "TRUE";
    } else {
      letToggleDeleted = "FALSE";
    }
    this.setState((prevState) => ({
      toggleDeleted: letToggleDeleted,
    }));

    filterValue.is_deleted = letToggleDeleted;

    // toggle functionality

    if (letToggleDeleted !== "FALSE") {
      // console.log("letToggleDeleted", letToggleDeleted);
      if (filterValue.is_disabled == "FALSE") {
        // console.log("Testing is_disabled FALSE", filterValue.is_disabled);
        this.myRefdisabled.current.checked = true;
        filterValue.is_disabled = "TRUE";
        this.setState({
          toggleDisabled: "TRUE",
        });
      }
    }

    // console.log("JSON.stringify(filterValue)", JSON.stringify(filterValue));
    localStorage.setItem("filterValue", JSON.stringify(filterValue));
    // return;
    this.setState((prevState) => ({
      toggleDeleted: filterValue.is_deleted,
    }));
    this.setState({ isloading: true });

    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prpareFilterValue = {
      ...filterValue,
      line_provider_id: getProviderID.value,
    };

    filterList(prpareFilterValue)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Res Filter", res);

          // const data = [...res.data];
          let data = JSON.parse(JSON.stringify(res.data));
          // console.log("toggleDeletedData", data);
          const updatedData = this.changeStrideValue(data);
          // console.log("toggleDeletedUpdate", updatedData);
          this.setState({
            isloading: false,
            listLine: updatedData,
            errorMessage: "",
            selectedRow: [],
            filterValue: filterValue,
          });
          // actions.resetForm();
        } else {
          console.log("Error Log");
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          console.log("Error Log catch");
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          console.log("Error Log catch else");
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  /* This function will give list of  */

  toggleDisabled = () => {
    // console.log("call function");
    let letToggleDisabled = "";
    if (this.state.toggleDisabled !== "TRUE") {
      letToggleDisabled = "TRUE";
    } else {
      letToggleDisabled = "FALSE";
    }
    this.setState((prevState) => ({
      toggleDisabled: letToggleDisabled,
    }));

    let filterValue = JSON.parse(localStorage.filterValue);
    filterValue.is_disabled = letToggleDisabled;
    // console.log("JSON.stringify(filterValue)", JSON.stringify(filterValue));
    localStorage.setItem("filterValue", JSON.stringify(filterValue));
    // return;
    this.setState((prevState) => ({
      toggleDisabled: filterValue.is_disabled,
    }));
    this.setState({ isloading: true });
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prpareFilterValue = {
      ...filterValue,
      line_provider_id: getProviderID.value,
    };
    filterList(prpareFilterValue)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Res Filter", res);

          let data = JSON.parse(JSON.stringify(res.data));
          // console.log("toggleDiableData", data);
          const updatedData = this.changeStrideValue(data);
          // console.log("ToggleDiableData", updatedData);

          this.setState({
            isloading: false,
            listLine: updatedData,
            errorMessage: "",
            selectedRow: [],
            filterValue: filterValue,
          });
          // actions.resetForm();
        } else {
          console.log("Error Log");
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          console.log("Error Log catch");
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          console.log("Error Log catch else");
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  handleChange = (e) => {
    const getValue = e.target.value;
    // console.log("getValue", getValue);
    this.setState({
      bulkActionSelect: getValue,
    });
  };

  handleSubmit = () => {
    if (this.state.bulkActionSelect !== "") {
      // console.log("handleSubmit bulk submit");
      const getProviderID = JSON.parse(localStorage.getItem("providerID"));
      const data = {
        line_id: this.state.selectedRow,
        bulk_action: this.state.bulkActionSelect,
        line_provider_id: getProviderID.value,
      };
      // console.log("bulk Line Update Data", data);
      // return;
      this.setState({
        buttonDisabled: true,
      });
      bulkUpdateLine(data)
        .then((res) => {
          if (res.status === 200) {
            // console.log("Res bulk Action", res);
            this.setState(
              {
                bulkActionSelect: "",
                selectedRow: [],
                buttonDisabled: false,
              },
              () => {
                this.getLineList();
                this.getEnableLine();
              }
            );
            toast.success("Action updated successfully.", {
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
        })
        .catch((error) => {
          if (error.data !== undefined) {
            this.setState({
              buttonDisabled: false,
            });
            toast.error(error.data.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
          } else {
            this.setState({
              buttonDisabled: false,
            });
            toast.error(error.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
        });
    } else {
      toast.error("Please select at least one option.", {
        autoClose: 3000,
        hideProgressBar: true,
      });
    }
  };

  /* This Function will open modal of xref */
  handleModalShowUpdateXref = () => {
    // console.log("handleModalShowUpdateXref");
    this.setState({
      showModalUpdateXref: true,
    });
  };

  /* This Function will close modal of xref */
  handleModalHideUpdateXref = () => {
    this.setState({
      showModalUpdateXref: false,
      errorMessage: "",
    });
  };

  /* This Function will update xref */
  updateXref = (data) => {
    // console.log("updateXref Data", data);
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prepareData = {
      line_id: this.state.selectedRow,
      xref_number: data.xref_number,
      line_provider_id: getProviderID.value,
    };

    // console.log("updateXref prepareData", prepareData);
    // return;
    this.setState({
      buttonDisabled: true,
    });
    updateXrefNumber(prepareData)
      .then((res) => {
        if (res.status === 200) {
          // console.log("response updateXref", res);
          this.setState(
            {
              updateXrefinitialValues: {
                xref_number: "",
              },
              showModalUpdateXref: false,
              buttonDisabled: false,
              selectedRow: [],
            },
            () => {
              this.getLineList();
            }
          );
          toast.success("Update Xref number successfully!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          console.log("Error else");
          this.setState({
            errorMessage: res.message,
            buttonDisabled: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error catch");
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            buttonDisabled: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            buttonDisabled: false,
          });
        }
      });
  };

  /* This function will call API and give enableLine list */
  getEnableLine = () => {
    console.log("getEnableLine");
    const getProviderIDValue = localStorage.getItem("providerID");
    const parseObject = JSON.parse(getProviderIDValue);
    console.log("getProviderID", parseObject);

    const data = {
      line_provider_id: parseObject.value,
    };
    enableLine(data)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Response", res);
          this.setState(
            {
              enableLineList: res.data,
            },
            () => {
              this.setDefaultValueEnableLine();
            }
          );
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  setDefaultValueEnableLine = () => {
    let filterValue = JSON.parse(localStorage.filterValue);
    // console.log("filterValue.carrier", filterValue.carrier);
    if (filterValue.carrier !== "ALL") {
      // console.log("filterValue.carrier ALL if", filterValue.carrier);
      const filterObject = this.state.enableLineList.filter(
        (carrier) => carrier.carrier === filterValue.carrier
      );
      this.setState(
        {
          lineEnabledCarrier: filterObject[0].carrier,
          lineEnabledCount: filterObject[0].lines_enabled,
        },
        () => {
          this.getLineList();
        }
      );
      // console.log(
      //   "filterValue.carrier ALL if",
      //   filterValue.carrier,
      //   filterObject
      // );
    } else {
      // console.log("filterValue.carrier ALL else", filterValue.carrier);
      filterValue.carrier = this.state.enableLineList[0].carrier;
      localStorage.setItem("filterValue", JSON.stringify(filterValue));
      const getFilterValue = localStorage.getItem("filterValue");
      const parseObject = JSON.parse(getFilterValue);
      this.setState(
        {
          lineEnabledCarrier: this.state.enableLineList[0].carrier,
          lineEnabledCount: this.state.enableLineList[0].lines_enabled,
          filterValue: parseObject,
        },
        () => {
          this.getLineList();
        }
      );
    }
  };

  /**  This function will give number of Lines enable for carrier
   * @param event object
   */
  handleChangeLineEnabled = (event) => {
    this.setState({
      lineEnabledCount: event.target.value,
    });
  };

  /** This function responcible for filtering the  carrier  */

  handleChangeLineEnabledCarrier = (event) => {
    const filterObject = this.state.enableLineList.filter(
      (carrier) => carrier.carrier === event.target.value
    );
    // console.log("filterObject", filterObject);
    this.setState({
      lineEnabledCarrier: event.target.value,
      lineEnabledCount: filterObject[0].lines_enabled,
    });

    let filterValue = JSON.parse(localStorage.filterValue);
    // console.log("filterValue select", filterValue);
    filterValue.carrier = event.target.value;
    localStorage.setItem("filterValue", JSON.stringify(filterValue));
    this.setState(
      {
        filterValue: filterValue,
      },
      () => {
        this.getLineList();
      }
    );
  };

  /** This Function will Open line enable modal
   * @parma e object
   */
  lineEnabled = (e) => {
    e.preventDefault();
    // console.log("CLick here to lineEnableds");
    this.setState({
      lineEnabledShowConfirmModal: true,
    });
  };

  /* This Function will closed line enable modal */
  handleModalHideConfirmModal = () => {
    this.setState({
      lineEnabledShowConfirmModal: false,
      buttonDisabled: false,
    });
  };

  /* This Function will call API and update line enable for carrier */

  updateEnableLine = () => {
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prepareData = {
      carrier: this.state.lineEnabledCarrier,
      limit: this.state.lineEnabledCount,
      line_provider_id: getProviderID.value,
    };
    // console.log("prepareData", prepareData);
    this.setState({
      buttonDisabled: true,
    });
    // return;
    updateEnableLine(prepareData)
      .then((res) => {
        if (res.status === 200) {
          // console.log("response update Enable Line", res);
          this.setState(
            {
              buttonDisabled: false,
              lineEnabledShowConfirmModal: false,
              errorMessage: "",
            },
            () => {
              this.getLineList();
              this.getEnableLine();
            }
          );
          toast.success("Update lines successfully!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          console.log("Error else");
          this.setState({
            errorMessage: res.message,
            buttonDisabled: false,
          });
        }
      })
      .catch((error) => {
        console.log("Error catch");
        if (error.data !== undefined) {
          this.setState({
            errorMessage: error.data.message,
            buttonDisabled: false,
          });
        } else {
          this.setState({
            errorMessage: error.message,
            buttonDisabled: false,
          });
        }
      });
  };

  /**  This function will give phone number of respective carrier_name
   * @param carrier_name string
   */
  phoneFormateLink = (carrier_name) => {
    // console.log("carrier_name", carrier_name);
    const filter = this.state.carrier.find(function (carrier) {
      // console.log("phoneFormate carrier", carrier, carrier_name);
      return carrier.carrier === carrier_name;
    });
    if (filter !== undefined) {
      return filter.phone_format;
    }
    // return filter;
  };

  /** This Function will give message of row transcript
   * @param data string
   * */

  getTranScript = (data) => {
    this.setState({
      transcriptMesssage: data,
    });
  };

  /**
   * Function Update telnyx forwarding number and Call the API
   * @author   Anil Sharma
   * @param    {Number} newValue    Update telnyx forwarding number
   * @param    {Object} row
   */

  handleCellEdit = (oldValue, newValue, row, column) => {
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prepareData = {
      line_id: row.line_id,
      telnyx_forwarding_number: newValue,
      line_provider_id: getProviderID.value,
    };
    if (oldValue !== newValue) {
      updateTelnyxNumber(prepareData)
        .then((res) => {
          if (res.status === 200) {
            // console.log("response update Telnyx Number", res);
            toast.success("Forwarding Number updated successfully!", {
              autoClose: 3000,
              hideProgressBar: true,
            });
          } else {
            console.log("Error else");
            toast.error(res.message, {
              autoClose: 3000,
              hideProgressBar: true,
            });
          }
        })
        .catch((error) => {
          console.log("Error catch");
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        });
    } else {
      // console.log("Condition false");
    }
  };

  truncateString = (str, num) => {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str;
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + "...";
  };

  transcriptShowInline = (e) => {
    // console.log("transcriptShowInline event", e);
    this.setState(
      {
        showFullTranscript: !this.state.showFullTranscript,
      },
      () => {
        // this.getLineList();
        if (this.state.showFullTranscript) {
          document.body.classList.add("show_full_transcript");
        } else {
          document
            .querySelector("body")
            .classList.remove("show_full_transcript");
        }
      }
    );
  };

  /**
   * Function Open Modal provider Call the API and get the provider list
   */

  showModalProvider = () => {
    const getFilterValue = JSON.parse(localStorage.getItem("filterValue"));
    this.setState({
      dataLoading: true,
      showModalUpdateProvider: true,
      updateProviderinitialValues: {
        ...this.state.updateProviderinitialValues,
        carrier: getFilterValue.carrier,
      },
    });
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));

    const prepareData = {
      carrier: getFilterValue.carrier,
      line_provider_id: getProviderID.value,
    };
    getLineProvider(prepareData)
      .then((res) => {
        if (res.status === 200) {
          // console.log("Response", res);
          this.setState({
            showModalUpdateProvider: true,
            updateProviderinitialValues: {
              ...this.state.updateProviderinitialValues,
              providers: res.data.providers,
              TotalLineNumber: res.data.total_number_of_lines,
            },
            dataLoading: false,
          });
        } else {
          console.log("getLineProvider else 200");
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error.data !== undefined) {
          console.log("getLineProvider catch block if");
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.setState({
            dataLoading: false,
            showModalUpdateProvider: false,
          });
        } else {
          console.log("getLineProvider catch block else");
          toast.error(error.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
          this.setState({
            dataLoading: false,
            showModalUpdateProvider: false,
          });
        }
      });
  };

  /**
   * Function Update provider data witj Call the API
   * @author   Anil Sharma
   * @param    {Object} data
   */

  updateProvider = (data, actions) => {
    this.setState({
      buttonDisabled: true,
    });
    const getProviderID = JSON.parse(localStorage.getItem("providerID"));
    const prepareData = {
      ...data,
      line_provider_id: getProviderID.value,
    };
    // console.log("updateProvider Data", prepareData);
    // return;
    updateLinesProvider(prepareData)
      .then((res) => {
        if (res.status === 200) {
          console.log("response Provider", res);
          this.setState(
            {
              updateProviderinitialValues: {
                carrier: "",
                providers: [],
                TotalLineNumber: "",
              },
              showModalUpdateProvider: false,
              buttonDisabled: false,
            },
            () => {
              this.getLineList();
            }
          );
          toast.success("Update Provider successfully!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          console.log("Error else");
          this.setState({
            buttonDisabled: false,
          });
          toast.error("Something went wrong!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.log("Error catch");
        if (error.data !== undefined) {
          this.setState({
            buttonDisabled: false,
          });
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          this.setState({
            buttonDisabled: false,
          });
          toast.error(error.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  };

  render() {
    // console.log("phoneFormate", this.phoneFormateLink("VERIZON"));
    const {
      isloading,
      buttonDisabled,
      enableFilter,
      initialValues,
      showUploadModal,
      errorMessage,
      errorCustomMessage,
      bulkAction,
      listLine,
      carrier,
      selectedRow,
      bulkLineSwitch,
      isDisabledBulkModal,
      bulkLineSwitchSnoopCall,
      isDisabledBulkModalSnoopCall,
      showModalAddLine,
      addLineinitialValues,
      showModalEditLine,
      editLineinitialValues,
      toggleDeleted,
      showModalUpdateXref,
      updateXrefinitialValues,
      lineEnabledCount,
      lineEnabledCarrier,
      enableLineList,
      lineEnabledButton,
      lineEnabledShowConfirmModal,
      showModalViewTranscript,
      transcriptMesssage,
      showFullTranscript,
      showModalUpdateProvider,
      updateProviderinitialValues,
      dataLoading,
      listProvider,
      providerValue,
    } = this.state;

    const { SearchBar } = Search;
    const columns = [
      {
        dataField: "line_id",
        text: "Line Id",
        formatter: (cell, row) => {
          return cell;
        },
      },
      // {
      //   dataField: "carrier",
      //   text: "Carrier",
      // },
      {
        dataField: "phone_number",
        text: "Phone Number",
        editable: false,
        formatter: (cell, row) => {
          const getFormate = this.phoneFormateLink(row.carrier);
          if (getFormate !== null) {
            const replceXref = getFormate.replaceAll(
              "${xref}",
              row.xref_number
            );
            const replcePhone = replceXref.replaceAll(
              "${phone}",
              row.phone_number
            );
            // console.log("replceValue", replcePhone);
            const linkPhoneNumber = <a href={`tel:${replcePhone}`}>{cell}</a>;
            return linkPhoneNumber;
          } else {
            // console.log("replceValue", "null");
            return cell;
          }
        },
      },
      {
        dataField: "xref_number",
        text: "XREF Number",
        classes: "td_xref_number",
        editable: false,
      },
      {
        dataField: "telnyx_forwarding_number",
        text: "Forwarding Number",
        classes: "td_telnex_number react-bootstrap-table-editing-cell",
        formatter: (cell, row) => {
          // console.log(cell, row);
          return (
            <>
              <input type="text" class="form-control" value={cell} readOnly />
            </>
          );
        },
      },
      {
        dataField: "is_disabled",
        text: "Is Enabled",
        editable: false,
        sort: true,
        classes: "text-center",
        formatter: (cell, row) => {
          return (
            <img
              className="table_img"
              src={row.is_disabled ? check_dis : check}
              alt="Not Found"
            />
          );
        },
      },
      {
        dataField: "success_count",
        text: "Success",
        editable: false,
        sort: true,
        headerClasses: "text-right",
        classes: "text-right",
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        formatter: (cell, row) => {
          console.log("cell", cell);
          const convert = parseInt(cell);
          return convert;
        },
      },
      {
        dataField: "failure_count",
        text: "Failure",
        classes: "text-right",
        headerClasses: "text-right",
        editable: false,
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        formatter: (cell, row) => {
          const convert = parseInt(cell);
          return convert;
        },
      },
      {
        dataField: "stride",
        text: "Stride",
        headerClasses: "text-right",
        editable: false,
        classes: "stride_colum text-right",
        sort: true,
      },
      {
        dataField: "is_last_result_successful",
        text: "Trending",
        headerClasses: "text-center",
        editable: false,
        classes: "stride_colum text-center",
        sort: true,
        sortFunc: (a, b, order, dataField, rowA, rowB) => {
          if (order === "asc") {
            return b - a;
          }
          return a - b; // desc
        },
        formatter: (cell, row) => {
          return (
            <>
              <img
                className="table_img"
                src={row.is_last_result_successful ? upArrow : curveDownArrow}
                alt="Not Found"
              />
            </>
          );
        },
      },
      {
        dataField: "is_available",
        text: "Available",
        editable: false,
        classes: "text-center",
        formatter: (cell, row) => {
          return (
            <img
              className="table_img"
              src={row.is_available ? check : close}
              alt="Not Found"
            />
          );
        },
      },
      {
        dataField: "snoop_next_call",
        text: "Snoop Next Call",
        editable: false,
        classes: "text-center",
        formatter: (cell, row) => {
          return (
            <>
              <div className="d-flex align-items-center set_table_td_icon_color">
                {row.snoop_next_call ? (
                  <div className="wrapper_switch">
                    <div className="switch_box">
                      <input
                        ref={this.myReftrue}
                        type="checkbox"
                        className="switch_1"
                        value="FALSE"
                        defaultChecked={row.snoop_next_call}
                        onChange={() =>
                          this.bulkLineUpdateSnoopCall("FALSE", row.line_id)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="wrapper_switch">
                    <div className="switch_box">
                      <input
                        ref={this.myReffalse}
                        type="checkbox"
                        className="switch_1"
                        value="TRUE"
                        defaultChecked={row.snoop_next_call}
                        onChange={() =>
                          this.bulkLineUpdateSnoopCall("TRUE", row.line_id)
                        }
                      />
                    </div>
                  </div>
                )}
                {row.recording_url !== null ? (
                  <div
                    className={`pointer_cursor ml-2 ${
                      row.snoop_call_successful ? "green" : "red"
                    }`}
                  >
                    <Music url={row.recording_url} />
                  </div>
                ) : null}
              </div>
              <div>
                {row.transcript !== null ? (
                  <div className="d-flex flex-column align-items-center set_table_td_icon_color mt-1 text-left">
                    <div style={{ fontSize: "12px" }}>
                      {moment(row.date_created).fromNow()}
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          );
        },
      },
      {
        dataField: "transcript",
        text: "Transcript",
        editable: false,
        classes: "text-center",
        headerClasses: "column_transcript_thead",
        formatter: (cell, row) => {
          return (
            <>
              {row.transcript !== null ? (
                <>
                  {showFullTranscript ? (
                    <p
                      className="text-left"
                      style={{ fontSize: "12px", marginBottom: "0px" }}
                    >
                      {row.transcript}
                    </p>
                  ) : (
                    <>
                      <label
                        className="table_top_label"
                        style={{ marginBottom: "0px" }}
                      >
                        <p
                          className="text-left text_truncate_column"
                          style={{ fontSize: "12px", marginBottom: "0px" }}
                        >
                          {row.transcript}
                        </p>
                        <span
                          id={`tooltip-top-transcript_${row.line_id}`}
                          className="circle_info text_truncate_column_tooltip"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                        <UncontrolledTooltip
                          placement="left"
                          target={`tooltip-top-transcript_${row.line_id}`}
                        >
                          {row.transcript}
                        </UncontrolledTooltip>
                      </label>
                    </>
                  )}
                </>
              ) : // <div className="d-flex flex-column align-items-center set_table_td_icon_color justify-content-center">
              //   <div
              //     className="pointer_cursor mr-2 text-center faStickyNote"
              //     onClick={() =>
              //       this.setState(
              //         {
              //           showModalViewTranscript: true,
              //         },
              //         () => this.getTranScript(row.transcript)
              //       )
              //     }
              //   >
              //     <FontAwesomeIcon icon={faStickyNote} />
              //   </div>
              // </div>
              null}
            </>
          );
        },
      },
      // {
      //   dataField: "action",
      //   text: "Action",
      //   formatter: (cell, row) => {
      //     // console.log("cell row", cell, row);
      //     return (
      //       <div
      //         className="line_edit_button"
      //         onClick={() => this.handleModalShowEditLine(row)}
      //       >
      //         <FontAwesomeIcon icon={faEdit} />
      //       </div>
      //     );
      //   },
      // },
    ];
    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        // console.log("rowEvents  ", e, row, rowIndex);
      },
    };
    if (this.myRefdeleted.current !== null) {
      let filterValueDeleted = JSON.parse(localStorage.filterValue);
      if (filterValueDeleted.is_deleted != "TRUE") {
        this.myRefdeleted.current.checked = false;
      } else {
        this.myRefdeleted.current.checked = true;
      }
    }

    if (this.myRefdisabled.current !== null) {
      let filterValueDisabled = JSON.parse(localStorage.filterValue);
      if (filterValueDisabled.is_disabled != "TRUE") {
        this.myRefdisabled.current.checked = false;
      } else {
        this.myRefdisabled.current.checked = true;
      }
    }
    // console.log("listLine", listLine);
    return (
      <div className="container-fluid">
        <div className="row justify-content-between align-items-center mb-4">
          <div className="col-md-3">
            <h3 className="module_title">Manage Lines</h3>
          </div>
          <div className="col-md-9">
            <div className="d-flex justify-content-end align-items-center">
              <div className="clearfix col-md-5">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="clearfix lable_select_provider">
                    <label>Select Provider</label>
                  </div>
                  <div className="clearfix parent_select_provider">
                    <Select
                      className="select-style basic-select"
                      classNamePrefix="react_select"
                      value={providerValue}
                      placeholder="Select Provider"
                      name="provider"
                      onChange={(selectProvider) =>
                        this.handleProviderChange(selectProvider)
                      }
                      options={listProvider}
                      noOptionsMessage={() => "No provider Found"}
                      isSearchable={true}
                      isClearable={false}
                    />
                  </div>
                </div>
              </div>
              <div className="clearfix text-right">
                <button
                  className="btn btn-primary btn-primary-theme"
                  onClick={this.showUploadModal}
                >
                  <FontAwesomeIcon icon={faUpload} />
                  <span className="ml-2">Upload Lines</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bulk_action_parent_wrapper">
          {selectedRow.length > 0 ? (
            <div className="bulk_action d-flex">
              {/* <label className="bold">Bulk Action</label> */}
              <div className="d-flex">
                <div className="bulk_action_icon">
                  <div className="select_wrapper">
                    <select
                      onChange={this.handleChange}
                      className="form-control"
                    >
                      <option value="" label="Select Action" />
                      <option value="FALSE" label="Enable" />
                      <option value="TRUE" label="Disable" />
                      <option value="is_deleted" label="Delete" />
                      <option value="is_un_deleted" label="Undelete" />
                    </select>
                  </div>
                  <button
                    className="btn btn-primary ml-3 btn-primary-theme"
                    onClick={this.handleSubmit}
                    disabled={buttonDisabled}
                  >
                    Apply
                  </button>
                </div>
                <button
                  className="btn btn-primary btn-primary-theme"
                  onClick={this.handleModalShowUpdateXref}
                >
                  <FontAwesomeIcon icon={faSyncAlt} />
                  <span className="ml-2">Update XREF</span>
                </button>
              </div>
            </div>
          ) : null}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="panel_body">
              <ToolkitProvider
                keyField="line_id"
                data={listLine}
                columns={columns}
                search
              >
                {(props) => (
                  <div>
                    <div className="table_SearchBar">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="table_left_wraper">
                            <form id="lineEnabled" onSubmit={this.lineEnabled}>
                              <div className="d-flex align-items-center ">
                                <div className="carrier_tdropdown">
                                  <label className="table_top_label">
                                    Select Carrier
                                  </label>
                                  <div className="select_wrapper mr-3">
                                    <select
                                      className="form-control"
                                      name="lineEnabledCarrier"
                                      value={lineEnabledCarrier}
                                      onChange={
                                        this.handleChangeLineEnabledCarrier
                                      }
                                    >
                                      {enableLineList.map((option, index) => {
                                        return (
                                          <>
                                            <option
                                              key={index}
                                              value={option.carrier}
                                              label={option.carrier}
                                            />
                                          </>
                                        );
                                      })}
                                    </select>
                                  </div>
                                </div>
                                <div className="enabled_line_input">
                                  <label className="table_top_label">
                                    Lines Enable &nbsp;
                                    <span
                                      id="tooltip-top"
                                      className="circle_info"
                                    >
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </span>
                                    <Tooltip
                                      placement="top"
                                      isOpen={this.state.tooltip1}
                                      target="tooltip-top"
                                      toggle={() => {
                                        this.setState({
                                          tooltip1: !this.state.tooltip1,
                                        });
                                      }}
                                    >
                                      Number of lines enabled for a carrier
                                    </Tooltip>
                                  </label>
                                  <div className="d-flex align-items-center">
                                    <input
                                      className="form-control mr-3"
                                      name="lineEnabledCount"
                                      type="number"
                                      value={lineEnabledCount}
                                      onChange={this.handleChangeLineEnabled}
                                    />
                                    <button
                                      className="btn btn-primary btn-primary-theme"
                                      disabled={lineEnabledButton}
                                    >
                                      <FontAwesomeIcon icon={faCheck} />
                                    </button>
                                  </div>
                                </div>
                                <div className="provider_link">
                                  <span
                                    onClick={() => this.showModalProvider()}
                                  >
                                    Manage Providers
                                  </span>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <label className="table_top_label"></label>
                          <div className="table_right_wraper">
                            <div className="wrapper_switch">
                              <span>Show Disabled</span>
                              <div className="switch_box">
                                <input
                                  ref={this.myRefdisabled}
                                  type="checkbox"
                                  className="switch_1"
                                  defaultChecked={this.state.toggleDisabled}
                                  onChange={() => this.toggleDisabled()}
                                />
                              </div>
                            </div>
                            <div className="wrapper_switch">
                              <span>Show Deleted</span>
                              <div className="switch_box">
                                <input
                                  ref={this.myRefdeleted}
                                  type="checkbox"
                                  className="switch_1"
                                  defaultChecked={toggleDeleted}
                                  onChange={() => this.toggleDeleted()}
                                />
                              </div>
                            </div>
                            <SearchBar
                              {...props.searchProps}
                              className="custome-search-table"
                              placeholder="Search line"
                            />
                            {!isloading ? (
                              <div className="show_inline_transcript_message">
                                <input
                                  type="checkbox"
                                  defaultChecked={this.state.showFullTranscript}
                                  onChange={(e) => this.transcriptShowInline(e)}
                                />
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr />

                    {isloading ? (
                      <div className="custom-loader linesLoader">
                        <Spinner animation="border" />
                      </div>
                    ) : (
                      <>
                        {" "}
                        <BootstrapTable
                          {...props.baseProps}
                          striped
                          hover
                          condensed
                          bordered={true}
                          noDataIndication={"No lines available"}
                          // noDataIndication={"No Lines Available"}
                          selectRow={{
                            mode: "checkbox",
                            classes: "custom-checkbox-class",
                            // clickToSelect: true,
                            onSelect: (row, isSelect, rowIndex, e) => {
                              // console.log("Select Row", row, isSelect, rowIndex);
                              if (isSelect) {
                                // console.log("on Select");
                                this.onSelectCheckbox(row);
                              } else {
                                // console.log("De Selected");
                                this.onUnSelectCheckbox(row);
                              }
                            },
                            onSelectAll: (isSelect, rows, e) => {
                              if (isSelect) {
                                // console.log("onSelectAll True", rows);
                                this.onAllSelectCheckbox(rows);
                              } else {
                                // console.log("onSelectAll false", rows);
                                this.onAllUnSelectCheckbox(rows);
                              }
                              // console.log("On Select All", isSelect, rows, e);
                            },
                          }}
                          cellEdit={cellEditFactory({
                            mode: "dbclick",
                            blurToSave: true,
                            afterSaveCell: (oldValue, newValue, row, column) =>
                              this.handleCellEdit(
                                oldValue,
                                newValue,
                                row,
                                column
                              ),
                          })}
                          pagination={paginationFactory({
                            sizePerPage: 100,
                            showTotal: true,
                            hideSizePerPage: true,
                          })}
                          rowEvents={rowEvents}
                          rowClasses="row-hover"
                        />
                      </>
                    )}
                  </div>
                )}
              </ToolkitProvider>
            </div>
          </div>
        </div>

        {showUploadModal ? (
          <UploadLine
            showModalUploadLine={showUploadModal}
            handleModalHide={this.handleModalHide}
            onSubmit={this.uploadLine}
            errorMessage={errorMessage}
            errorCustomMessage={errorCustomMessage}
          />
        ) : null}
        {isDisabledBulkModal ? (
          <BulkAction
            showModalBulkAction={isDisabledBulkModal}
            handleModalHide={this.bulkLineDisabled}
            onSubmit={this.bulkLineUpdate}
            errorMessage={errorMessage}
            label="Enable / Disable Lines"
            radio1="Enabled"
            radio2="Disabled"
            isloading={buttonDisabled}
          />
        ) : null}

        {isDisabledBulkModalSnoopCall ? (
          <BulkAction
            showModalBulkAction={isDisabledBulkModalSnoopCall}
            handleModalHide={this.bulkLineDisabledSnoopCall}
            onSubmit={this.bulkLineUpdateSnoopCall}
            errorMessage={errorMessage}
            label="Snoop Call"
            radio1="True"
            radio2="False"
            isloading={buttonDisabled}
          />
        ) : null}

        {showModalUpdateXref ? (
          <UpdateXref
            showModalUpdateXref={showModalUpdateXref}
            handleModalHide={this.handleModalHideUpdateXref}
            onSubmit={this.updateXref}
            initialValues={updateXrefinitialValues}
            errorMessage={errorMessage}
            isloading={buttonDisabled}
          />
        ) : null}

        {lineEnabledShowConfirmModal ? (
          <ShowConfirmModal
            lineEnabledShowConfirmModal={lineEnabledShowConfirmModal}
            handleModalHide={this.handleModalHideConfirmModal}
            onSubmit={this.updateEnableLine}
            errorMessage={errorMessage}
            isloading={buttonDisabled}
            dataCarrier={lineEnabledCarrier}
            dataCount={lineEnabledCount}
          />
        ) : null}
        {
          <ViewTranscript
            handleModalHide={() =>
              this.setState({
                showModalViewTranscript: false,
              })
            }
            handleModalShow={showModalViewTranscript}
            message={transcriptMesssage}
          />
        }
        {showModalUpdateProvider ? (
          <UpdateProvider
            showModalUpdateProvider={showModalUpdateProvider}
            handleModalHide={() =>
              this.setState({
                showModalUpdateProvider: false,
              })
            }
            lineEnabledCount={lineEnabledCount}
            onSubmit={this.updateProvider}
            initialValues={updateProviderinitialValues}
            isloading={buttonDisabled}
            dataLoading={dataLoading}
          />
        ) : null}
      </div>
    );
  }
}
export default ManageLines;
