import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle, faPauseCircle } from "@fortawesome/free-solid-svg-icons";

class Music extends Component {
  // Create state
  state = {
    // Get audio file in a variable
    audio: new Audio(this.props.url),

    // Set initial state of song
    isPlaying: false,
  };

  // Main function to handle both play and pause operations
  playPause = () => {
    // Get state of song
    let isPlaying = this.state.isPlaying;

    if (isPlaying) {
      // Pause the song if it is playing
      this.state.audio.pause();
    } else {
      // Play the song if it is paused
      this.state.audio.play();
    }

    // Change the state of song
    this.setState({ isPlaying: !isPlaying });
  };

  componentDidMount() {
    this.state.audio.addEventListener("ended", () =>
      this.setState({ isPlaying: false })
    );
  }

  componentWillUnmount() {
    this.state.audio.removeEventListener("ended", () =>
      this.setState({ isPlaying: false })
    );
  }

  render() {
    return (
      <div>
        <span onClick={this.playPause}>
          {this.state.isPlaying ? (
            <FontAwesomeIcon icon={faPauseCircle} />
          ) : (
            <FontAwesomeIcon icon={faPlayCircle} />
          )}
        </span>
      </div>
    );
  }
}

export default Music;
