import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

toast.configure();
export default class ResetPasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hidden: true,
    };
  }

  render() {
    const { isloading, error } = this.props;
    // console.log("this.props", this.props);
    return (
      <>
        <div className="clearfix login_left_col">
          <h1>Change Password</h1>
          <p>Please enter your new password.</p>
          {
            <Formik
              initialValues={{
                new_password: "",
                confirm_password: "",
                reset_token: this.props.queryParam,
              }}
              onSubmit={(values) => {
                // console.log("values", values);
                delete values["confirm_password"];
                // return;
                this.props.onSubmit(values);
              }}
              validationSchema={Yup.object().shape({
                new_password: Yup.string().required("Password is required"),
                confirm_password: Yup.string().when("new_password", {
                  is: (val) => (val && val.length > 0 ? true : false),
                  then: Yup.string()
                    .required("Password is required")
                    .oneOf(
                      [Yup.ref("new_password")],
                      "Password are not matching"
                    ),
                }),
              })}
            >
              {(props) => {
                const {
                  errors,
                  handleChange,
                  handleSubmit,
                  handleBlur,
                  touched,
                } = props;
                return (
                  <form onSubmit={handleSubmit}>
                    {this.props.error !== "" ? (
                      <p className="alert alert-danger">{this.props.error}</p>
                    ) : (
                      ""
                    )}
                    <div className={`form-group`}>
                      <label>New Password</label>
                      <div className="input-group">
                        <input
                          type="password"
                          name="new_password"
                          onBlur={handleBlur}
                          className={
                            errors.new_password && touched.new_password
                              ? "form-control error"
                              : "form-control"
                          }
                          placeholder=""
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <FontAwesomeIcon icon={faKey} />
                          </div>
                        </div>

                        {errors.new_password && touched.new_password && (
                          <div className="input-feedback error_field">
                            {errors.new_password}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className={`form-group`}>
                      <label>Confirm New Password</label>
                      <div className="input-group">
                        <input
                          type="password"
                          name="confirm_password"
                          onBlur={handleBlur}
                          className={
                            errors.confirm_password && touched.confirm_password
                              ? "form-control error"
                              : "form-control"
                          }
                          placeholder=""
                          onChange={handleChange}
                        />
                        <div className="input-group-append">
                          <div className="input-group-text">
                            <FontAwesomeIcon icon={faKey} />
                          </div>
                        </div>

                        {errors.confirm_password &&
                          touched.confirm_password && (
                            <div className="input-feedback error_field">
                              {errors.confirm_password}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between mt-5">
                      <button
                        type="submit"
                        className="btn btn-primary-fill d-block text-center mt-5 w-100"
                        disabled={isloading}
                      >
                        {!isloading ? "Update" : "Please Wait"}
                      </button>
                      <Link className={"forgot-link"} to="/">
                        Back to Login
                      </Link>
                    </div>
                  </form>
                );
              }}
            </Formik>
          }
        </div>
      </>
    );
  }
}
