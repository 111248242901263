import React, { useEffect, useState } from "react";

const FormInput = (props) => {
  const [toggle, setToggle] = useState(true);
  const [value, setValue] = useState(props.defaultValue);
  const [oldValue, setOldValue] = useState(props.defaultValue);
  const [rowId, setRowId] = useState(props.inputId);

  const toggleInput = () => {
    setToggle(false);
  };

  const handleChangeBlur = () => {
    props.updateConfiguration(oldValue, value, rowId);
    setToggle(true);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  return (
    <div>
      {toggle ? (
        <p className="formElementDefaultValue" onDoubleClick={toggleInput}>
          {value}
        </p>
      ) : (
        <input
          className="form-control bg_change_color1"
          type="text"
          name={`key_input_element_${props.index}`}
          defaultValue={props.defaultValue}
          onChange={handleChange}
          onBlur={handleChangeBlur}
        />
      )}
    </div>
  );
};

export default FormInput;
