import React, { memo } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
function Sidebar(props) {
  // function handleLogout() {
  //   localStorage.removeItem("roles");
  //   history.push("/");
  // }

  return (
    <div
      className={props.handleClass ? "main-layout" : "main-layout open"}
      id="side-menu"
    >
      <aside className="left-sidebar-layout ">
        <div className="sidebar-content">
          <ul>
            {props.routes
              .filter(
                (route) => route.hidden === undefined || route.hidden !== true
              )
              .map(({ path, title, icon }) => {
                // console.log("path, title, icon", path, title, icon);
                return (
                  <li key={title}>
                    <NavLink
                      key={path}
                      className="sidebar-menu-item"
                      to={`${props.prefix}${path}`}
                    >
                      <FontAwesomeIcon icon={icon} />
                      <span
                        className={props.handleClass ? "menu hide" : "menu"}
                      >
                        {title}
                      </span>
                    </NavLink>
                  </li>
                );
              })}
          </ul>
        </div>
      </aside>
    </div>
  );
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
  prefix: PropTypes.string,
  className: PropTypes.string,
};

Sidebar.defaultProps = {
  prefix: "",
  className: "",
};

export default memo(Sidebar);
