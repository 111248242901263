export function getAuthRedirectUrl() {
  let role = JSON.parse(localStorage.getItem("roleIds"));
  console.log("Get role", role);
  switch (role) {
    case 1:
      return "/app/dashboard";
    case 2:
      return "/app/manage-lines";
    default:
      return "/";
  }
}
