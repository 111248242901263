import { Api } from "../Api";

export const userUpdate = (data) => {
  return Api.post("services/user/update", data).then((res) => res.data);
};

export const passwordUpdate = (data) => {
  return Api.post("services/user/password-update", data).then(
    (res) => res.data
  );
};
