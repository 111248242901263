import React, { useState, useEffect } from "react";
import { Row, Col } from "reactstrap";
import ResetPasswordForm from "./ResetPasswordForm";
import { resetPassword, tokenExpire } from "../services/AuthServices";
import { ToastContainer, toast } from "react-toastify";
toast.configure();

const ResetPassword = () => {
  const params = new URLSearchParams(window.location.search);
  const paramsValue = params.get("reset_token");
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [isValid, setIsValid] = useState(true);
  console.log("params Value check", params.has("reset_token"));
  //   if (params.has("reset_token") !== false) {
  //     setIsValid(false);
  //   }

  useEffect(() => {
    const data = {
      reset_token: paramsValue,
    };
    tokenExpire(data)
      .then((res) => {
        console.log("Change password Response", res);
        if (res.status === 200) {
          setIsValid(true);
        } else {
          setIsValid(false);
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error !== undefined) {
          console.log("error If catch");
          setIsValid(false);
        } else {
          console.log("error else catch");
          setIsValid(false);
        }
      });
  }, []);

  function onSubmitForgotPassword(data) {
    console.log("onSubmitForgotPassword", data);
    setIsloading(true);
    resetPassword(data)
      .then((res) => {
        console.log("Change password Response", res);
        setIsloading(false);
        toast.success(res.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
        setTimeout(
          function () {
            setError("");
          }.bind(this),
          3000
        );
        if (error !== undefined) {
          setIsloading(false);
          setError(error.data.message);
          toast.error(error.data.message, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          setIsloading(false);
          setError(error.data.message);
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  }

  return (
    <section>
      <div className="container-fluid login_page">
        <Row className="align-items-center justify-content-center">
          <Col lg="5">
            {isValid ? (
              <ResetPasswordForm
                error={error}
                onSubmit={onSubmitForgotPassword}
                isloading={isloading}
                queryParam={paramsValue}
              />
            ) : (
              <p className="text-center">Your link has expired</p>
            )}
          </Col>
          <Col lg="7" className="login_right_section p-0">
            <div className="login_bg_image"></div>
          </Col>
        </Row>
      </div>
    </section>
  );
};
export default ResetPassword;
