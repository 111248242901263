import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./app.scss";
import Login from "./auth/Login";
import Main from "./components/common/Main";
import ResetPassword from "./auth/ResetPassword";

function App() {
  return (
    <React.Fragment>
      <Router>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/app" component={Main} />
        </Switch>
      </Router>
    </React.Fragment>
  );
}

export default App;
