import React, { Component } from "react";
import { Link } from "react-router-dom";
import SimpleLineIcon from "react-simple-line-icons";
import { getAuthRedirectUrl } from "../../utils/General";
import UpdateProfile from "../../profile/UpdateProfile";
import ChangePassword from "../../profile/ChangePassword";
import { userUpdate, passwordUpdate } from "../../services/UserServices";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isloading: false,
      updateProfileModal: false,
      initialValues: {
        name: "",
        user_id: "",
      },

      changePasswordModal: false,
      initialValuesChangePassword: {
        old_password: "",
        new_password: "",
        user_id: "",
      },
    };
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    const parseObject = JSON.parse(user);
    // console.log("user", user, parseObject);
    this.setState({
      initialValues: {
        name: parseObject.name,
        user_id: parseObject.user_id,
      },
      initialValuesChangePassword: {
        user_id: parseObject.user_id,
        old_password: "",
        new_password: "",
      },
    });
  }

  handleModalShowUpdateProfile = () => {
    this.setState({
      updateProfileModal: true,
    });
  };

  handleModalHideUpdateProfile = () => {
    this.setState({
      updateProfileModal: false,
    });
  };

  updateProfile = (data) => {
    console.log("Update Profile data", data);
    this.setState({ isloading: true });
    userUpdate(data)
      .then((res) => {
        if (res.status === 200) {
          console.log("Res update Profile", res);
          let user = JSON.parse(localStorage.user);
          user.name = data.name;
          localStorage.setItem("user", JSON.stringify(user));
          this.setState({
            isloading: false,
            updateProfileModal: false,
            errorMessage: "",
            initialValues: data,
          });
          toast.success("Profile updated successfully!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
        } else {
          console.log("Error Log");
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          console.log("Error Log catch");
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          console.log("Error Log catch else");
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  updatePassword = (data) => {
    console.log("Update password data", data);
    this.setState({ isloading: true });

    passwordUpdate(data)
      .then((res) => {
        if (res.status === 200) {
          console.log("Res update password", res);
          this.setState({
            isloading: false,
            changePasswordModal: false,
            errorMessage: "",
          });
          toast.success("Password updated successfully!", {
            autoClose: 3000,
            hideProgressBar: true,
          });
          localStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error Log");
        }
      })
      .catch((error) => {
        if (error.data !== undefined) {
          console.log("Error Log catch");
          this.setState({
            errorMessage: error.data.message,
            isloading: false,
          });
        } else {
          console.log("Error Log catch else");
          this.setState({
            errorMessage: error.message,
            isloading: false,
          });
        }
      });
  };

  handleLogout = () => {
    localStorage.clear();
    window.location.href = "/";
  };
  render() {
    let userData = JSON.parse(localStorage.getItem("user"));
    const {
      updateProfileModal,
      errorMessage,
      initialValues,
      isloading,
      changePasswordModal,
      initialValuesChangePassword,
    } = this.state;
    return (
      <header className="header-layout">
        <div className="logo-sec">
          <i
            className="icon-menu icon"
            id="barIcon"
            onClick={() => this.props.handletoggle()}
          ></i>
          ReachOut <span className="logo_tag_line">by Sofmen</span>
        </div>
        <div className="header-right">
          <div className="header-icons dropdown">
            <span
              className="profile-btn dropdown-toggle"
              id="profile"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <span
                className="clearfix"
                style={{
                  marginRight: "10px",
                  display: "inline-block",
                }}
              >
                <SimpleLineIcon name="people" />
              </span>
              <span style={{ cursor: "pointer" }}>
                {userData !== undefined && userData !== null && userData.name}
              </span>
            </span>
            <ul className="dropdown-menu">
              <li>
                <a
                  className="dropdown-item"
                  onClick={this.handleModalShowUpdateProfile}
                >
                  Profile
                </a>
              </li>
              <li>
                <a
                  className="dropdown-item"
                  onClick={() => this.setState({ changePasswordModal: true })}
                >
                  Change Password
                </a>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={this.handleLogout}
                >
                  {" "}
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {updateProfileModal ? (
          <UpdateProfile
            updateProfileModal={updateProfileModal}
            handleModalHide={this.handleModalHideUpdateProfile}
            initialValues={initialValues}
            onSubmit={this.updateProfile}
            errorMessage={errorMessage}
            isloading={isloading}
          />
        ) : null}

        {changePasswordModal ? (
          <ChangePassword
            changePasswordModal={changePasswordModal}
            handleModalHide={() =>
              this.setState({ changePasswordModal: false, errorMessage : "" })
            }
            initialValues={initialValuesChangePassword}
            onSubmit={this.updatePassword}
            errorMessage={errorMessage}
            isloading={isloading}
          />
        ) : null}
      </header>
    );
  }
}
export default Header;
