import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Formik } from "formik";
import { getAuthRedirectUrl } from "../utils/General";
import * as Yup from "yup";
import { login, forgotPassword } from "../services/AuthServices";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import LoginForm from "./LoginForm";
import ForgotPassword from "./ForgotPassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      formErrors: { email: "", password: "" },
      emailValid: false,
      passwordValid: false,
      formValid: false,
      loggedIn: "",
      error: "",
      isloading: false,
      hidden: true,
      forgotPassword: false,
    };
  }

  onSubmit = (user) => {
    this.setState({ isloading: true });
    // return;
    login(user)
      .then((result) => {
        if (result !== undefined && result.token !== undefined) {
          console.log("Login Response", result);
          localStorage.setItem("token", result.token);
          localStorage.setItem("user", JSON.stringify(result));
          // let roleIds = [];
          // let defaultRole = [];
          // result.userRoles.map((user) => {
          //   roleIds.push(user.roleId);
          //   if (user.defaultRole === true) defaultRole.push(user.roleId);
          // });
          // localStorage.setItem("defaultRole", JSON.stringify(defaultRole));
          localStorage.setItem("roleIds", result.role);
          this.setState({ isloading: false });
          this.props.history.push(getAuthRedirectUrl());
        } else {
          this.setState({ error: result.message, isloading: false });
        }
      })
      .catch((error) => {
        console.log("error", error);
        if (error !== undefined) {
          this.setState({ error: error.message, isloading: false });
        }
      });
  };

  onSubmitForgotPassword = (data) => {
    console.log("Forgot Email data", data);
    this.setState({ isloading: true });
    // return;
    forgotPassword(data)
      .then((res) => {
        console.log("Forgot Response", res);
        this.setState({ isloading: false });
        toast.success(res.message, {
          autoClose: 3000,
          hideProgressBar: true,
        });
      })
      .catch((error) => {
        console.log("error", error);
        if (error !== undefined) {
          this.setState({ error: error.data.message, isloading: false });
        } else {
          this.setState({ isloading: false, error: error.data.message });
          toast.error(error, {
            autoClose: 3000,
            hideProgressBar: true,
          });
        }
      });
  };

  componentDidMount() {
    document.title = "Login";
    if (localStorage.getItem("token") !== null) {
      this.props.history.push(getAuthRedirectUrl());
    }
  }

  toggleForms = (e) => {
    e.preventDefault();
    this.setState({ forgotPassword: !this.state.forgotPassword, error: "" });
  };

  render() {
    const { forgotPassword, error, isloading } = this.state;
    return (
      <section>
        <div className="container-fluid login_page">
          <Row className="align-items-center justify-content-center">
            <Col lg="5">
              {!forgotPassword ? (
                <LoginForm
                  error={error}
                  onSubmit={this.onSubmit}
                  isloading={isloading}
                  toggleForms={this.toggleForms}
                />
              ) : (
                <ForgotPassword
                  error={error}
                  onSubmit={this.onSubmitForgotPassword}
                  isloading={isloading}
                  toggleForms={this.toggleForms}
                  domainName={window.location.hostname}
                />
              )}
            </Col>
            <Col lg="7" className="login_right_section p-0">
              <div className="login_bg_image"></div>
            </Col>
          </Row>
        </div>
      </section>
    );
  }
}

export default Login;
