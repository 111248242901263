import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

toast.configure();
export default class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  closeModal = (resetForm) => {
    resetForm();
    this.props.handleModalHide();
  };

  render() {
    const { isloading } = this.props;
    return (
      <>
        <Formik
          enableReinitialize
          initialValues={this.props.initialValues}
          onSubmit={(values, actions) => {
            // console.log(values);
            // return;
            this.props.onSubmit(values, actions);
          }}
          validationSchema={Yup.object().shape({
            old_password: Yup.string().required("Please select old password"),
            new_password: Yup.string().required("Please select new password"),
          })}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Modal isOpen={this.props.changePasswordModal} size="md">
                <ModalHeader>
                  <h4>Update Password</h4>
                  <div className="modal_popup_close_icon">
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => this.closeModal(resetForm)}
                    />
                  </div>
                </ModalHeader>
                <ModalBody>
                  {this.props.errorMessage !== "" ? (
                    <p className="alert alert-danger">
                      {this.props.errorMessage}
                    </p>
                  ) : (
                    ""
                  )}
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="12">
                        <label>Old Password</label>
                      </Col>
                      <Col md="12">
                        <input
                          type="password"
                          name="old_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.old_password}
                          className="form-control"
                          placeholder="Enter your old password"
                        />
                        <label className="err-msg">
                          {errors.old_password &&
                            touched.old_password &&
                            errors.old_password}
                        </label>
                      </Col>
                      <input
                        type="hidden"
                        name="user_id"
                        value={values.user_id}
                      />
                    </Row>
                  </div>
                  <div className="form-group form-gp">
                    <Row>
                      <Col md="12">
                        <label>New Password</label>
                      </Col>
                      <Col md="12">
                        <input
                          type="password"
                          name="new_password"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.new_password}
                          className="form-control"
                          placeholder="Enter your new password"
                        />
                        <label className="err-msg">
                          {errors.new_password &&
                            touched.new_password &&
                            errors.new_password}
                        </label>
                      </Col>
                    </Row>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="secondary"
                    type="button"
                    onClick={() => this.closeModal(resetForm)}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn btn-primary-theme"
                    type="submit"
                    color="success"
                    onClick={handleSubmit}
                    disabled={isloading}
                  >
                    Submit
                  </Button>
                </ModalFooter>
              </Modal>
            </form>
          )}
        </Formik>
      </>
    );
  }
}
