import React, { Component } from "react";
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "reactstrap";
// import { Formik, Field } from "formik";
// import * as Yup from "yup";
// import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

// toast.configure();
export default class ShowConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isloading, dataCarrier, dataCount } = this.props;
    return (
      <>
        <form onSubmit={() => this.props.onSubmit()}>
          <Modal isOpen={this.props.lineEnabledShowConfirmModal} size="md">
            <ModalHeader>
              <h4>Confirm</h4>
              <div className="modal_popup_close_icon">
                <FontAwesomeIcon
                  icon={faTimes}
                  onClick={() => this.props.handleModalHide()}
                />
              </div>
            </ModalHeader>
            <ModalBody>
              {this.props.errorMessage !== "" ? (
                <p className="alert alert-danger">{this.props.errorMessage}</p>
              ) : (
                ""
              )}
              <p>
                Do you want to enable {dataCount} lines for carrier &nbsp;
                {dataCarrier} ?
              </p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="secondary"
                type="button"
                onClick={() => this.props.handleModalHide()}
              >
                Close
              </Button>
              <Button
                className="btn btn-primary-theme"
                type="submit"
                color="success"
                disabled={isloading}
                onClick={() => this.props.onSubmit()}
              >
                Yes, Enable
              </Button>
            </ModalFooter>
          </Modal>
        </form>
      </>
    );
  }
}
