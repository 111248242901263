import axios from "axios";

// Dvelopment & Production URL

const Login_BASE_URL = process.env.REACT_APP_LOGIN_BASE_URL || "";
const BASE_URL = process.env.REACT_APP_BASE_URL || "";

export const Api = axios.create({
  baseURL: BASE_URL,
});

export const ApiLogin = axios.create({
  baseURL: Login_BASE_URL,
});

Api.interceptors.request.use(function (config) {
  var token = localStorage.getItem("token");
  if (token !== undefined && token !== null) {
    config.headers.common["Authorization"] = `${token}`;
    config.headers.common["Content-Type"] = "application/json";
  }

  return config;
});

Api.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      console.log("unauthorized, logging out ...");
      localStorage.removeItem("token");
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error.response);
  }
);
